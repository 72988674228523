import React from "react";

import {
  AiFillGithub,
  AiFillMediumCircle,
  AiFillTwitterCircle,
  AiFillLinkedin,
} from "react-icons/ai";

import styles from "./SocialMediaIcons.module.css";

const SocialMediaIcons = () => {
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>Get in Touch</h2>
        <div className={styles.iconContainer}>
          <AiFillGithub size={90} className={styles.icon} />
          <AiFillMediumCircle size={90} className={styles.icon} />
          <AiFillTwitterCircle size={90} className={styles.icon} />
          <AiFillLinkedin size={90} className={styles.icon} />
        </div>
      </div>
    </>
  );
};

export default SocialMediaIcons;

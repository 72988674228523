import { useState, useEffect } from 'react';
import MainNavigation from '@components/UI/Navigation/MainNavigation';
import SocialMediaIcons from '@components/UI/SocialMediaIcons/SocialMediaIcons';
import Footer from '@components/Footer/Footer';
import LottiePlayer from '@components/UI/LottiePlayer/LottiePlayer';
import useBasicInput from '@hooks/UseBasicInput';

import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Typewriter from 'typewriter-effect';

import contact from '@assets/animations/contact.json';
import send from '@assets/icons/send.svg';

import styles from './ContactPage.module.css';

const isNotEmpty = (value) => value.trim() !== "";
const isEmailValid = (value) => value.includes("@");

const scrollDownStyles = {
  width: "200px",
  height: "200px",
};

const ContactPage = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    enteredValue: enteredName,
    enteredValueIsValid: enteredNameIsValid,
    hasError: isNameInvalid,
    valueInputChangeHandler: nameInputChangeHandler,
    valueInputBlurHandler: nameInputBlurHandler,
    reset: resetName,
  } = useBasicInput(isNotEmpty);

  const {
    enteredValue: enteredEmail,
    enteredValueIsValid: enteredEmailIsValid,
    hasError: isEmailInvalid,
    valueInputChangeHandler: emailInputChangeHandler,
    valueInputBlurHandler: emailInputBlurHandler,
    reset: resetEmail,
  } = useBasicInput(isEmailValid);

  const {
    enteredValue: enteredMessage,
    enteredValueIsValid: enteredMessageIsValid,
    hasError: isMessageInvalid,
    valueInputChangeHandler: messageInputChangeHandler,
    valueInputBlurHandler: messageInputBlurHandler,
    reset: resetMessage,
  } = useBasicInput(isNotEmpty);

  const submitHandler = (event) => {
    event.preventDefault();

    setIsLoading(true);

    const newMessage = {
      name: enteredName,
      email: enteredEmail,
      message: enteredMessage,
    };

    console.log(newMessage);

    resetName();
    resetEmail();
    resetMessage();

    setIsLoading(false);
  };

  const nameInputClasses = isNameInvalid
    ? `${styles.invalid}`
    : `${styles.nameInputField}`;

  const emailInputClasses = isEmailInvalid
    ? `${styles.invalid}`
    : `${styles.nameInputField}`;
  const messageInputClasses = isMessageInvalid
    ? `${styles.invalidMessage}`
    : `${styles.messageInputField}`;

  let isFormValid =
    enteredNameIsValid && enteredEmailIsValid && enteredMessageIsValid;

  return (
    <>
      <MainNavigation contact={true} />
      <motion.div
        className={styles.contactContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.4 }}
        >
          <LottiePlayer animationData={contact} style={scrollDownStyles} />
        </motion.div>

        <motion.div
          className={styles.title}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.4 }}
        >
          Let's talk <p className={styles.typewriter}></p>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(1000)
                .typeString("Business")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Start-ups")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Tech")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Innovation")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Entrepreneurship")
                .pauseFor(1000)
                .deleteAll()
                .typeString("URG")
                .pauseFor(1000)
                .start();
            }}
            options={{
              loop: true,
            }}
          />
        </motion.div>
        <motion.form
          onSubmit={submitHandler}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.5, duration: 1.2 }}
        >
          <div className={styles.formContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.formLabel} htmlFor="name">
                {t("Name.1")}
              </label>
              <input
                className={nameInputClasses}
                type="text"
                value={enteredName}
                onChange={nameInputChangeHandler}
                onBlur={nameInputBlurHandler}
              />
              {isNameInvalid && (
                <p className={styles.errorMessage}>
                  {" "}
                  <strong className={styles.strong}>|</strong>{" "}
                  {t("NameError.1")}
                </p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.formLabel} htmlFor="email">
                E-MAIL
              </label>
              <input
                className={emailInputClasses}
                type="text"
                value={enteredEmail}
                onChange={emailInputChangeHandler}
                onBlur={emailInputBlurHandler}
              />
              {isEmailInvalid && (
                <p className={styles.errorMessage}>
                  <strong className={styles.strong}>|</strong>{" "}
                  {t("MailError.1")}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.formLabel} htmlFor="message">
              {t("Message.1")}
            </label>
            <textarea
              className={messageInputClasses}
              type="text"
              value={enteredMessage}
              onChange={messageInputChangeHandler}
              onBlur={messageInputBlurHandler}
            />
            {isMessageInvalid && (
              <p className={styles.errorMessage}>
                <strong className={styles.strong}>|</strong>{" "}
                {t("MessageError.1")}
              </p>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <motion.button
              className={styles.send}
              disabled={!isFormValid || isLoading}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              {isLoading ? "Spinner" : t("Send.1")}
              <ReactSVG src={send} />
            </motion.button>
          </div>
        </motion.form>
        <SocialMediaIcons />
      </motion.div>
      <Footer />
    </>
  );
};

export default ContactPage;

import React from "react";

import {
  AiFillGithub,
  AiFillMediumCircle,
  AiFillTwitterCircle,
} from "react-icons/ai";

import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div>
      <div className={styles.appStoreContainer}>
        <div>
          <AiFillMediumCircle size={70} />
          <a
            className={styles.github}
            rel="noreferrer"
            target="_blank"
            href="https://github.com/urg0"
          >
            <AiFillGithub size={70} />
          </a>
          <AiFillTwitterCircle size={70} />
        </div>
      </div>

      <div className={styles.footer}>
        <footer>All rights reserved. BBD Tech © 2023</footer>
        <footer className={styles.urg}> URG.</footer>
      </div>
    </div>
  );
};

export default Footer;

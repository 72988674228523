import Lottie from "react-lottie";
export default function LottiePlayer({ animationData, style }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  /*   return <></>; */

  return <Lottie options={defaultOptions} style={style} />;
}

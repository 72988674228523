import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import { ReactSVG } from "react-svg";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

import MainNavigation from "@components/UI/Navigation/MainNavigation";
import WelcomePage from "@components/WelcomePage/WelcomePage";
import AboutUs from "@components/AboutUsPage/AboutUs";
import ExpertisePage from "@components/ExpertisePage/ExpertisePage";
import Footer from "@components/Footer/Footer";
import ProductsPage from "@components/ProductsPage/ProductsPage";
import InViewAnimation from "@components/InViewAnimation/InViewAnimation";

import contact from "@assets/icons/contact.svg";

import styles from "./HomePage.module.css";

const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLightTheme, setIsLightTheme] = useState(false);

  const toggleThemeHandler = () => {
    setIsLightTheme((prevState) => !prevState);
  };

  return (
    <div className={styles.container}>
      <div
        className={
          isLightTheme
            ? `${styles.welcomeContainer} ${styles.light}`
            : styles.welcomeContainer
        }
      >
        <Element name="home">
          <section className={styles.header}>
            <MainNavigation
              changeTheme={toggleThemeHandler}
              isLightMode={isLightTheme}
            />
            <WelcomePage isLightMode={isLightTheme} />
          </section>
        </Element>
        <Element name="about-us">
          <section className={styles.aboutUsContainer}>
            <AboutUs isLightMode={isLightTheme} />
          </section>
          <section className={styles.expertiseContainer}>
            <ExpertisePage isLightMode={isLightTheme} />
          </section>
        </Element>
        <Element name="products">
          <section className={styles.productsContainer}>
            <ProductsPage isLightMode={isLightTheme} />
          </section>
        </Element>
        <section className={styles.contact}>
          <div className={styles.contactTitleContainer}>
            <h2 className={styles.title}>{t("Wait.1")}</h2>
            <h2 className={styles.subtitle}>{t("Next.1")}</h2>
          </div>
          <InViewAnimation>
            <motion.button
              className={styles.contactButton}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              onClick={() => navigate("contact")}
            >
              <ReactSVG src={contact} className={styles.icon} />
              {t("ContactButton.1")}
            </motion.button>
          </InViewAnimation>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;

import React from "react";

import { ReactSVG } from "react-svg";

import appStore from "@assets/images/appstore.png";
import googlePlay from "@assets/images/googleplay.png";

import styles from "./Product.module.css";

const Product = ({ isLightMode, logo, website, app }) => {
  return (
    <>
      <div
        className={
          isLightMode ? `${styles.container} ${styles.light}` : styles.container
        }
      >
        <div className={styles.titleContainer}>
          <ReactSVG src={logo} />
          <div className={styles.previewContainer}>
            <img className={styles.teslaPreview} src={website} alt="iphone" />
            <div className={styles.imageContainer}>
              <img className={styles.appLogo} src={app} alt="iphone" />
              <div className={styles.storeContainer}>
                <img className={styles.appStore} src={appStore} alt="iphone" />
                <img
                  className={styles.googlePlay}
                  src={googlePlay}
                  alt="iphone"
                />
              </div>
            </div>
          </div>
          <div className={styles.productContainer}></div>
          <div className={styles.infoContainer}>
            <p className={styles.info}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt
            </p>
            <p className={styles.info}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
              fugiat a saepe repudiandae porro nesciunt assumenda obcaecati
              provident, minima quasi uas fugit accusantium ducimus voluptatum
              ut maxime nam illo expedita.
            </p>
            <p className={styles.info}>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloribus voluptatum laborum quos natus modi animi quo rerum
              voluptate debitis perferendis.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;

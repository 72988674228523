import React from "react";

import InViewAnimation from "@components/InViewAnimation/InViewAnimation";

import { useTranslation } from "react-i18next";

import image from "@assets/images/test.png";
import collaboration from "@assets/images/collaboration.png";
import ai from "@assets/images/ai.png";
import digital from "@assets/images/digital.png";
import productivity from "@assets/images/productivity.png";
import virtualReality from "@assets/images/virtual-reality.png";
import newTechnologies from "@assets/images/new-technologies.png";

import styles from "./Expertise.module.css";

const Expertise = ({ isLightMode }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={
          isLightMode ? `${styles.container} ${styles.light}` : styles.container
        }
      >
        <InViewAnimation>
          <h2 className={styles.title}>{t("Expertise.1")}</h2>
          <div className={styles.description}>{t("ExpertiseMainTitle1.1")}</div>
          <div className={styles.description}>{t("ExpertiseMainTitle2.1")}</div>
        </InViewAnimation>
        <InViewAnimation>
          <div className={styles.expertiseContainer}>
            <img className={styles.test} src={image} alt="test" />
            <div className={styles.expertise}>
              <h2 className={styles.expertiseTitle}>
                {t("ExpertiseProduct.1")}
              </h2>
              <h2 className={styles.expertiseTitle}>
                {t("ExpertiseProduct2.1")}
              </h2>
              <p className={styles.expertiseDescription}>
                {t("ExpertiseProductInfo.1")}
              </p>
            </div>
          </div>
        </InViewAnimation>
        <InViewAnimation>
          <div className={styles.expertiseContainer}>
            <div className={styles.expertise}>
              <h2 className={styles.expertiseTitle}>{t("ExpertiseTeam.1")}</h2>
              <h2 className={styles.expertiseTitle}>
                {t("ExpertiseTeam2.1")}{" "}
              </h2>
              <p className={styles.expertiseDescription}>
                {t("ExpertiseTeamInfo.1")}
              </p>
            </div>
            <img className={styles.test} src={collaboration} alt="test" />
          </div>
        </InViewAnimation>
        <InViewAnimation>
          <div className={styles.expertiseContainer}>
            <div className={styles.imageContainer}>
              <div className={styles.imageContainer2}>
                <img
                  className={styles.productivity}
                  src={productivity}
                  alt="test"
                />
                <img
                  className={styles.newTechnologies}
                  src={newTechnologies}
                  alt="test"
                />
              </div>
              <img className={styles.ai} src={ai} alt="test" />
              <div className={styles.imageContainer3}>
                <img
                  className={styles.virtualReality}
                  src={virtualReality}
                  alt="test"
                />
                <img className={styles.digital} src={digital} alt="test" />
              </div>
            </div>
            <div className={styles.expertise3}>
              <h2 className={styles.expertiseTitle}>
                {t("ExpertiseMindset.1")}
              </h2>

              <p className={styles.expertiseDescription}>
                {t("ExpertiseMindsetInfo.1")}
              </p>
            </div>
          </div>
        </InViewAnimation>
      </div>
    </>
  );
};

export default Expertise;

import React from "react";

import { motion } from "framer-motion";

import icon from "@assets/icons/bbd-logo.png";

import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.container}>
      <motion.img
        src={icon}
        className={styles.logo}
        alt="logo"
        animate={{
          scale: [1, 2, 2, 1, 1],
          rotate: [0, 0, 360, 370, 0],
        }}
        transition={{
          duration: 2,
          once: true,
        }}
      />
    </div>
  );
};

export default Loading;

import React from "react";

import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { ReactSVG } from "react-svg";

import icon from "@assets/icons/bbd-icon.png";
import moon from "@assets/icons/moon.svg";
import sun from "@assets/icons/sun.svg";

import styles from "./MainNavigation.module.css";

import { Link } from "react-scroll";

const MainNavigation = ({ changeTheme, isLightMode, contact = false }) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const isEnglish = i18n.language === "en";
  const englishStyle = isEnglish ? styles.languagesActive : styles.languages;
  const turkishStyle = !isEnglish ? styles.languagesActive : styles.languages;

  const changeLanguageHandler = (language) => {
    i18n.changeLanguage(language);
  };

  const navLinkStyle = ({ isActive }) => {
    return isActive ? styles.active : styles.link;
  };

  const isElementInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  const isNavLinkActive = (sectionName) => {
    const element = document.querySelector(`[name=${sectionName}]`);
    return element && isElementInViewport(element);
  };

  const navigateHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const themeButtonIcon = isLightMode ? moon : sun;

  return (
    <motion.div
      className={
        isLightMode ? `${styles.navigation} ${styles.light}` : styles.navigation
      }
      initial={{ y: -250 }}
      animate={{ y: -10 }}
      transition={{ delay: 0.5, type: "spring", stiffness: 120 }}
    >
      <motion.img
        className={styles.logo}
        src={icon}
        alt="bbd-logo"
        initial={{
          scale: 1,
          rotate: 0,
        }}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 0, 360, 370, 0],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatDelay: 3,
          delay: 3,
        }}
      />
      <nav className={styles.navigationContainer}>
        <Link
          activeClass={styles.active}
          to="home"
          spy={true}
          smooth={true}
          duration={500}
          className={styles.link}
          onClick={navigateHome}
        >
          {t("Home.1")}
        </Link>
        {!contact && (
          <>
            <Link
              activeClass={styles.active}
              to="about-us"
              spy={true}
              smooth={true}
              duration={500}
              className={
                isNavLinkActive("about-us")
                  ? `${styles.active}`
                  : `${styles.link}`
              }
            >
              {t("About.1")}
            </Link>
            <Link
              activeClass={styles.active}
              to="products"
              spy={true}
              smooth={true}
              duration={500}
              className={
                isNavLinkActive("products")
                  ? `${styles.active}`
                  : `${styles.link}`
              }
            >
              Products
            </Link>
          </>
        )}
        <NavLink to="/contact" className={navLinkStyle}>
          {t("Contact.1")}
        </NavLink>
        <div className={styles.languageContainer}>
          <button
            className={englishStyle}
            onClick={() => changeLanguageHandler("en")}
          >
            ENG
          </button>
          <button
            className={turkishStyle}
            onClick={() => changeLanguageHandler("tr")}
          >
            TR
          </button>
        </div>
        <ReactSVG
          className={styles.darkMode}
          src={themeButtonIcon}
          onClick={changeTheme}
        />
      </nav>
    </motion.div>
  );
};

export default MainNavigation;

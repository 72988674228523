import InViewAnimation from '@components/InViewAnimation/InViewAnimation';

import LottiePlayer from '@components/UI/LottiePlayer/LottiePlayer';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import computer from '@assets/animations/computer.json';
import monitors from '@assets/animations/monitors.json';
import cloud from '@assets/animations/saas-cloud.json';
import software from '@assets/animations/software.json';

import styles from './AboutUs.module.css';

const scrollDownStyles = {
  width: "150px",
  height: "150px",
  marginTop: "30px",
};

const AboutUs = ({ isLightMode }) => {
  const { t } = useTranslation();
  return (
    <InViewAnimation>
      <div
        className={
          isLightMode ? `${styles.container} ${styles.light}` : styles.container
        }
      >
        <div className={styles.animationFrame}>
          <LottiePlayer animationData={computer} style={scrollDownStyles} />
          <LottiePlayer animationData={monitors} style={scrollDownStyles} />
        </div>
        <div className={styles.animationContainer}>
          <div className={styles.spinningIconContainer}>
            <LottiePlayer animationData={cloud} style={scrollDownStyles} />
          </div>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{t("Elevating.1")}</h2>
            <h2 className={styles.title}>{t("Next-Gen.1")}</h2>
            <div>
              <InViewAnimation>
                <motion.h2 className={styles.jobDescription}>
                  {" "}
                  {t("SaaS.1")}{" "}
                </motion.h2>
              </InViewAnimation>

              <h2 className={styles.title}>{t("Solutions.1")}</h2>
              <p className={styles.description}>{t("Users.1")}</p>
            </div>
            <LottiePlayer animationData={software} style={scrollDownStyles} />
          </div>
          <div className={styles.spinningIconContainer}>
            <LottiePlayer animationData={cloud} style={scrollDownStyles} />
          </div>
        </div>
      </div>
    </InViewAnimation>
  );
};

export default AboutUs;

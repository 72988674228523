import icon from "@assets/icons/bbd-logo.png";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import LottiePlayer from "@components/UI/LottiePlayer/LottiePlayer";

import scrollDown from "@assets/animations/scroll-down.json";

import styles from "./WelcomePage.module.css";

const scrollDownStyles = {
  width: "100px",
  height: "100px",
};

const WelcomePage = ({ isLightMode }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        isLightMode ? `${styles.container} ${styles.light}` : styles.container
      }
    >
      <div className={styles.logoWrapper}>
        <motion.img
          src={icon}
          className={styles.logo}
          alt="logo"
          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 360, 370, 0],
          }}
          transition={{
            duration: 2,
            once: true,
          }}
        />
      </div>
      <div className={styles.titleContainer}>
        <motion.h2
          className={styles.welcomeTitle}
          initial={{ scaleY: 50 }}
          animate={{ opacity: 1, fontSize: 30, scaleY: 1 }}
          transition={{ delay: 0.5, duration: 0.3 }}
        >
          {t("Welcome.1")}
        </motion.h2>
        <motion.h2
          className={styles.subtitle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.4 }}
        >
          {t("BBD Tech.1")}
        </motion.h2>
      </div>
      <div className={styles.scrollDownFrame}>
        <LottiePlayer
          animationData={scrollDown}
          style={scrollDownStyles}
          className={styles.animation}
        />
      </div>
    </div>
  );
};

export default WelcomePage;

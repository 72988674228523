import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./routes.js";
import { AnimatePresence } from "framer-motion";

import "./App.css";

const App = () => {
  return (
    <>
      <AnimatePresence>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </AnimatePresence>
    </>
  );
};

export default App;

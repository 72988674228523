import React from "react";

import InViewAnimation from "@components/InViewAnimation/InViewAnimation";
import { useTranslation } from "react-i18next";

/* import Slider from "components/UI/Slider/Slider"; */

import styles from "./ProductsPage.module.css";

import grab from "@assets/icons/grab.svg";
import ikea from "@assets/icons/ikea.svg";
import tesla from "@assets/icons/tesla.svg";
import tesla1 from "@assets/images/tesla1.png";
import teslaPreview from "@assets/images/teslaPreview.png";
import ikeaApp from "@assets/images/ikeaApp.png";
import ikeaWeb from "@assets/images/ikeaWeb.png";
import grabApp from "@assets/images/grabApp.png";
import grabWeb from "@assets/images/grabWeb.png";
import Product from "./Product/Product";

const ProductsPage = ({ isLightMode }) => {
  const { t } = useTranslation();

  return (
    <>
      <InViewAnimation>
        <div
          className={
            isLightMode
              ? `${styles.container} ${styles.light}`
              : styles.container
          }
        >
          <div className={styles.title}> {t("Product.1")}</div>
          <p className={styles.subtitle}>{t("ProductsTitle.1")}</p>
        </div>
        {/* <Slider isLightMode={isLightMode} /> */}
      </InViewAnimation>
      <InViewAnimation>
        <Product
          isLightMode={isLightMode}
          logo={tesla}
          website={teslaPreview}
          app={tesla1}
        />
      </InViewAnimation>
      <InViewAnimation>
        <Product
          isLightMode={isLightMode}
          logo={ikea}
          website={ikeaWeb}
          app={ikeaApp}
        />
      </InViewAnimation>
      <InViewAnimation>
        <Product
          isLightMode={isLightMode}
          logo={grab}
          website={grabWeb}
          app={grabApp}
        />
      </InViewAnimation>
    </>
  );
};

export default ProductsPage;

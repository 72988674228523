import { useRoutes } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import ContactPage from "./components/ContactPage/ContactPage";

export const AppRouter = () => {
  const elements = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/contact",
      element: <ContactPage />,
    },
  ]);

  return elements;
};
